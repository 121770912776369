import { createRouter, createWebHashHistory } from "vue-router";
import LayOut from "../views/LayOut.vue";
import Hao from "../views/home/Hao.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/account",
    name: "account",
    component: LayOut,
    children: [{ path: "", component: Hao }],
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// ���token�Ƿ���ڣ�Ĭ��token��Ч��Ϊ24Сʱ
function isTokenExpired(token) {
  if (!token) return true;
  
  try {
    // ��localStorage��ȡ��¼ʱ��
    const store = localStorage.getItem('erabbit-client-pc-124-store');
    if (!store) return true;
    
    const data = JSON.parse(store);
    const loginTime = data.user?.loginTime || 0;
    const now = Date.now();
    
    // 24Сʱ = 86400000����
    return now - loginTime > 86400000;
  } catch (error) {
    console.error('���token����ʱ����:', error);
    return true;
  }
}

router.beforeEach((to, from, next) => {
  console.log('·������������Ŀ��·��:', to.path);
  
  // ��localStorage��ȡvuex״̬
  const store = localStorage.getItem('erabbit-client-pc-124-store') 
    ? JSON.parse(localStorage.getItem('erabbit-client-pc-124-store')) 
    : {};
  
  const token = store.user?.profile?.token;
  const isAuthenticated = token && !isTokenExpired(token);
  console.log('��֤״̬:', isAuthenticated);
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      console.log('��Ҫ��¼Ȩ�޻�token�ѹ��ڣ��ض��򵽵�¼ҳ��');
      next({ path: '/' });
    } else {
      console.log('����������Ҫ��֤��ҳ��');
      next();
    }
  } else {
    // ����ѵ�¼�ҷ��ʵ�¼ҳ���ض����˺�ҳ��
    if (isAuthenticated && to.path === '/') {
      console.log('�ѵ�¼���ض����˺�ҳ��');
      next({ path: '/account' });
    } else {
      console.log('�������ʲ���Ҫ��֤��ҳ��');
      next();
    }
  }
});

export default router;
