"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-758d4022"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "common-layout" };
var _hoisted_2 = { style: { "float": "left" } };
var _hoisted_3 = { class: "dialog-footer" };
var _hoisted_4 = { class: "dialog-footer" };
var axios_1 = require("axios");
var vue_3 = require("vue");
var icons_vue_1 = require("@element-plus/icons-vue");
var upload_acc_img_vue_1 = require("./components/upload-acc-img.vue");
var pagination_lol_vue_1 = require("./components/pagination-lol.vue");
var lol_img_card_vue_1 = require("./components/lol-img-card.vue");
var vue_router_1 = require("vue-router");
var element_plus_1 = require("element-plus");
var lolaccount_1 = require("../../api/lolaccount");
var vuex_1 = require("vuex");
var vue_4 = require("vue");
// import _ from 'lodash'
exports.default = (0, vue_1.defineComponent)({
    __name: 'Hao',
    setup: function (__props) {
        var _ = require('lodash');
        var qs = require('qs');
        var tableData = (0, vue_3.ref)(); //用于显示的数据
        var allData = (0, vue_3.ref)(); //总数据 不变
        var totalAccount = (0, vue_3.ref)(10);
        var router = (0, vue_router_1.useRouter)();
        (0, vue_4.onBeforeMount)(function () {
            //获取数据
            (0, lolaccount_1.getLOLAccount)().then(function (res) {
                tableData.value = res.data;
                allData.value = res.data;
                totalAccount.value = res.data.length;
                console.log(tableData.value);
                console.log(res.data); //有connectID
                chunkTableData = _.chunk(res.data, 15);
                tableData.value = chunkTableData[0];
                //验证token是不是存在
                if (store.state.user.profile.token) {
                    changeLogStatus(true);
                }
            });
        });
        // tableData.value = tableData.value.map((item)=>{
        //   item.modify = '修改'
        //   return item
        // })
        console.log(tableData);
        //用于修改的row索引 v-if控制input 控制'修改' '完成修改'
        var modifyRowIndex = (0, vue_3.ref)(-1);
        var modifyFlag = (0, vue_3.ref)(false); //是否在修改中 false不在修改 true修改中 控制修改or完成修改
        // 添加一个统一的跳转到登录页面的方法
        var redirectToLogin = function (message) {
            if (message === void 0) { message = '需要登录，正在跳转到登录页面...'; }
            (0, element_plus_1.ElMessage)({
                type: 'warning',
                message: message,
                duration: 1500
            });
            // 立即使用window.location.replace进行跳转，更直接的方式
            setTimeout(function () {
                // 尝试几种不同的跳转方式
                console.log('尝试跳转到登录页面...');
                try {
                    window.location.replace('#/');
                }
                catch (error) {
                    console.error('跳转失败，尝试其他方式:', error);
                    window.location.href = '#/';
                }
            }, 800);
        };
        // 修改方法使用统一的跳转函数
        var modifyMe = function (rowMSG, index) {
            if (!logStatus.value) {
                redirectToLogin('需要登录才能修改账号，正在跳转到登录页面...');
                return;
            }
            //一开始 不在修改 的时候点击 
            if (!modifyFlag.value) {
                modifyRowIndex.value = index + 1;
                console.log('yi开始 第一次');
            }
            else {
                //修改中点击
                //行号相等
                var area = rowMSG.area, myAccount = rowMSG.myAccount, password = rowMSG.password, realPassword = rowMSG.realPassword, myID = rowMSG.myID, nowRank = rowMSG.nowRank, lastRank = rowMSG.lastRank, hero = rowMSG.hero, id = rowMSG.id;
                //id是第几行账号 area大区 connectID是验证token后传入的ev_users的id
                var updateMSG = {
                    area: area,
                    myAccount: myAccount,
                    password: password,
                    realPassword: realPassword,
                    myID: myID,
                    nowRank: nowRank,
                    lastRank: lastRank,
                    hero: hero,
                    id: id,
                    connectID: store.state.user.profile.id,
                };
                (0, lolaccount_1.updateLOLAccount)(updateMSG).then(function (res) {
                    if (res.status === 0) {
                        (0, element_plus_1.ElMessage)({
                            message: '修改成功 O(∩_∩)O',
                            type: 'success',
                            offset: 40
                        });
                    }
                    else {
                        (0, element_plus_1.ElMessage)({
                            message: '修改失败,请重试',
                            type: 'error',
                            offset: 40
                        });
                    }
                });
                modifyRowIndex.value = -1;
            }
            modifyFlag.value = !modifyFlag.value;
        };
        var store = (0, vuex_1.useStore)();
        //登录状态相关变量
        var logText = (0, vue_3.ref)('请登录'); //
        var logStatus = (0, vue_3.ref)(false); //登录状态 是否登录
        var loginoutType = (0, vue_3.ref)('primary');
        var loginout = function () {
            if (logStatus.value) {
                //退出 清空token和用户信息
                console.log('tuichu');
                store.commit('user/setUser', {});
                logText.value = '请登录';
                logStatus.value = false;
                console.log('退出');
                // 显示提示消息
                (0, element_plus_1.ElMessage)({
                    type: 'info',
                    message: '已退出登录，正在返回登录页面...',
                    duration: 1000
                });
                // 直接使用location.replace进行跳转，这是一种更直接的方式
                console.log('立即跳转到登录页面');
                setTimeout(function () {
                    window.location.replace('#/');
                }, 500);
            }
            else {
                //未登录状态，直接跳转到登录页面
                console.log('跳转到登录页面');
                window.location.replace('#/');
            }
        };
        //登录组件登录成功emit触发
        var changeLogStatus = function (Bol) {
            //如果登录状态为true 关闭dialog
            if (Bol) {
                console.log('????????? 获取realPassword ???????????');
                console.log(store.state.user.profile.id);
                //获取realPassword
                (0, lolaccount_1.getRealPassword)().then(function (res) {
                    console.log(res, '获取realPassword');
                    // 修改这里：将密码应用到 allData，而不仅仅是 tableData
                    allData.value = allData.value.map(function (dataItem, index) {
                        dataItem.realPassword = res.data[index];
                        return dataItem;
                    });
                    // 重新生成分页数据，确保所有分页数据都有解密后的密码
                    chunkTableData = _.chunk(allData.value, Number(tableData.value.length || 15));
                    tableData.value = chunkTableData[0];
                });
                logStatus.value = true;
                logText.value = '退出该账号';
                loginoutType.value = 'success';
            }
        };
        //注册
        // registerUser(username,password).then((res)=>{
        //   console.log(res);
        // })
        // registerUser(username,password)
        /* 显示密码 */
        var visibleRowPSW = (0, vue_3.ref)([]); //显示密码的row
        var visibleRealPassword = function (psw, rowindex) {
            if (logStatus.value && store.state.user.profile.token) { //登录了
                console.log(rowindex);
                console.log(visibleRowPSW.value);
                //隐藏
                if (visibleRowPSW.value.includes(rowindex)) {
                    for (var i = 0; i < visibleRowPSW.value.length; i++) {
                        console.log('??del');
                        if (rowindex == visibleRowPSW.value[i]) {
                            visibleRowPSW.value.splice(i, 1);
                            console.log('delete');
                        }
                    }
                }
                else {
                    //显示
                    visibleRowPSW.value.push(rowindex);
                }
            }
            else {
                //没登录，使用统一的重定向方法
                redirectToLogin('需要登录才能查看密码，正在跳转到登录页面...');
            }
        };
        //关闭所有
        var closeMe = function () {
            element_plus_1.ElNotification.closeAll();
        };
        var open1 = function (message) {
            (0, element_plus_1.ElNotification)({
                title: "复制成功!",
                message: message,
                type: "success",
                duration: 1800,
                onClick: closeMe,
            });
        };
        var copyOrderId2 = function (orderId) {
            var input = document.createElement("input"); // 创建input对象
            input.value = orderId; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            open1(orderId);
            // setTimeout(() => {
            //   ElNotification.closeAll();
            // }, 300);
        };
        var cellHandleclick = function (row, column, cell, event) {
            // console.log(cell.children[0].innerText);
            //如果规定点击某一列执行，利用column中的label属性
            // copyOrderId2(cell.children[0].innerText);
        };
        //复制账号
        var copymyAccount = function (accountText) {
            copyOrderId2(accountText);
        };
        var enterMe = function (row, column, cell, event) {
            console.log("row", row);
            console.log("column", column);
            console.log("event", event);
            // tableRowClassName();
            // event.path[1].classList.add("wzh-row");
            // console.log(event.path[1]);
        };
        // let leaveMe = (row, column, cell, event) => {
        //   event.path[1].classList.remove("wzh-row");
        // };
        //显示图片
        var dialogVisibleIMG = (0, vue_3.ref)(-1); //显示第几行的就存数字进去
        var imgDialogVisible = (0, vue_3.ref)(true);
        //点击修改当前显示的
        // const imgDialogVisibleChange = (num)=>{
        //   dialogVisibleIMG.value = num
        // }
        var chunkTableData = null;
        var changePageSize = function (num) {
            // 确保使用已包含解密密码的 allData 来创建新的分页数据
            chunkTableData = _.chunk(allData.value, num);
            console.log(chunkTableData);
            changeCurPage(1);
        };
        var changeCurPage = function (num) {
            tableData.value = chunkTableData[num - 1];
            console.log(tableData.value);
        };
        var codeimg = (0, vue_3.ref)(null);
        var imgsvisible = (0, vue_3.ref)(true);
        var imgDialogGlance = (0, vue_3.ref)(-1); //显示第几个账号的图片
        var imgUrls = (0, vue_3.ref)([]); //保存图片地址
        var HaogetAccImg = function (id, rowindex) {
            // 检查登录状态
            if (!logStatus.value || !store.state.user.profile.token) {
                redirectToLogin('需要登录才能查看截图，正在跳转到登录页面...');
                return;
            }
            imgDialogGlance.value = rowindex;
            // 添加请求拦截器
            axios_1.default.interceptors.request.use(function (config) {
                // 在发送请求之前做些什么
                config.headers.Authorization = "Bearer ".concat(store.state.user.profile.token);
                return config;
            }, function (error) {
                // 对请求错误做些什么
                return Promise.reject(error);
            });
            var showImg = function (param) {
                return axios_1.default.request({
                    url: 'http://175.178.184.119:3009/my/getAccImg',
                    method: 'get',
                    params: param,
                    // responseType: 'blob'
                });
            };
            showImg({ id: id }).then(function (res) {
                //单文件 数据流获取图片 不好用
                // const myblob = new Blob([res.data],{type:'image/jpeg'})
                // codeimg.value = window.URL.createObjectURL(res.data)
                if (res.data.status === 0) {
                    (0, element_plus_1.ElMessage)({
                        type: 'success',
                        message: "\u56FE\u7247\u4EEC\u83B7\u53D6\u6210\u529F.",
                    });
                    imgUrls.value = res.data.urlArr;
                }
                else {
                    (0, element_plus_1.ElMessage)({
                        type: 'error',
                        message: "\u56FE\u7247\u4EEC\u83B7\u53D6\u5931\u8D25.\u6CA1\u6709\u56FE\u7247or\u53D1\u9001\u9519\u8BEF",
                    });
                    imgUrls.value = [];
                }
                console.log(res.data.urlArr);
            });
        };
        var gotoUpload = function (rowIndex) {
            // 检查登录状态
            if (!logStatus.value || !store.state.user.profile.token) {
                redirectToLogin('需要登录才能上传截图，正在跳转到登录页面...');
                return;
            }
            dialogVisibleIMG.value = rowIndex;
            imgDialogGlance.value = -1;
        };
        var handleAddScreenshot = function (index) {
            // 检查登录状态
            if (!logStatus.value || !store.state.user.profile.token) {
                redirectToLogin('需要登录才能添加截图，正在跳转到登录页面...');
                return;
            }
            dialogVisibleIMG.value = index;
            imgDialogGlance.value = -1;
        };
        return function (_ctx, _cache) {
            var _component_el_button = (0, vue_2.resolveComponent)("el-button");
            var _component_el_header = (0, vue_2.resolveComponent)("el-header");
            var _component_el_input = (0, vue_2.resolveComponent)("el-input");
            var _component_el_table_column = (0, vue_2.resolveComponent)("el-table-column");
            var _component_el_dialog = (0, vue_2.resolveComponent)("el-dialog");
            var _component_el_table = (0, vue_2.resolveComponent)("el-table");
            var _component_el_main = (0, vue_2.resolveComponent)("el-main");
            var _component_el_container = (0, vue_2.resolveComponent)("el-container");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_el_container, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_el_header, {
                            height: "60px",
                            style: { "padding-top": "0px", "font-weight": "600", "line-height": "60px" }
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createElementVNode)("span", _hoisted_2, [
                                    (0, vue_2.createTextVNode)("欢迎您,剑冢. "),
                                    (0, vue_2.createVNode)(_component_el_button, {
                                        type: (0, vue_2.unref)(loginoutType),
                                        plain: "",
                                        onClick: loginout,
                                        style: { "line-height": "60px", "margin-left": "10px" }
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(logText)), 1)
                                        ]; }),
                                        _: 1
                                    }, 8, ["type"])
                                ]),
                                (0, vue_2.createVNode)(pagination_lol_vue_1.default, {
                                    style: { "display": "inline-block", "float": "right" },
                                    totalAccount: (0, vue_2.unref)(totalAccount),
                                    onChangePageSize: changePageSize,
                                    onChangeCurPage: changeCurPage
                                }, null, 8, ["totalAccount"])
                            ]; }),
                            _: 1
                        }),
                        (0, vue_2.createVNode)(_component_el_container, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_el_main, null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_el_table, {
                                            onCellClick: (0, vue_2.unref)(cellHandleclick),
                                            data: (0, vue_2.unref)(tableData),
                                            stripe: "",
                                            style: { "width": "100%", "font-weight": "600" }
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "area",
                                                    label: "大区",
                                                    width: "110px"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.area,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.area) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "myAccount",
                                                    label: "账号",
                                                    width: "180px"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.myAccount,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.myAccount) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true),
                                                        (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(scope.$index !== modifyRowIndex.value - 1 ? scope.row.myAccount : '') + " ", 1),
                                                        (scope.$index !== modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                                                key: 1,
                                                                type: "primary",
                                                                onClick: function ($event) { return ((0, vue_2.unref)(copymyAccount)(scope.row.myAccount)); },
                                                                style: { "float": "right" }
                                                            }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createTextVNode)(" 复制 ")
                                                                ]; }),
                                                                _: 2
                                                            }, 1032, ["onClick"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "password",
                                                    label: "密码",
                                                    width: "90px"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.password,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.password) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "realPassword",
                                                    label: "完整密码",
                                                    width: "130px"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.realPassword,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.realPassword) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true),
                                                        (!(0, vue_2.unref)(logStatus) || !(0, vue_2.unref)(visibleRowPSW).includes(scope.$index) && scope.$index + 1 !== modifyRowIndex.value)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                                                key: 1,
                                                                type: "primary",
                                                                plain: "",
                                                                onClick: function ($event) { return (visibleRealPassword(scope.row.realPassword, scope.$index)); },
                                                                style: { "float": "right" }
                                                            }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createTextVNode)(" 显示完整密码 ")
                                                                ]; }),
                                                                _: 2
                                                            }, 1032, ["onClick"]))
                                                            : (0, vue_2.createCommentVNode)("", true),
                                                        (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)((0, vue_2.unref)(visibleRowPSW).includes(scope.$index) && scope.$index + 1 !== modifyRowIndex.value ? scope.row.realPassword : '') + " ", 1),
                                                        ((0, vue_2.unref)(visibleRowPSW).includes(scope.$index) && scope.$index + 1 !== modifyRowIndex.value)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                                                key: 2,
                                                                type: "primary",
                                                                plain: "",
                                                                onClick: function ($event) { return (visibleRealPassword(scope.row.realPassword, scope.$index)); },
                                                                style: { "float": "right" }
                                                            }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createTextVNode)(" 隐藏泥亩 ")
                                                                ]; }),
                                                                _: 2
                                                            }, 1032, ["onClick"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "myID",
                                                    label: "ID",
                                                    width: "150px"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.myID,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.myID) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "nowRank",
                                                    label: "段位"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.nowRank,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.nowRank) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "lastRank",
                                                    label: "上赛季段位"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.lastRank,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.lastRank) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    prop: "hero",
                                                    label: "英雄数",
                                                    width: "110px"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (scope.$index === modifyRowIndex.value - 1)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_input, {
                                                                key: 0,
                                                                modelValue: scope.row.hero,
                                                                "onUpdate:modelValue": function ($event) { return ((scope.row.hero) = $event); }
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    label: "",
                                                    prop: "modify"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                            plain: "",
                                                            type: "primary",
                                                            disabled: modifyRowIndex.value == scope.$index + 1 || modifyRowIndex.value == -1 ? false : true,
                                                            icon: (0, vue_2.unref)(icons_vue_1.Edit),
                                                            onClick: function ($event) { return (modifyMe(scope.row, scope.$index)); }
                                                        }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(scope.$index + 1 == modifyRowIndex.value ? '完成修改' : '修改'), 1)
                                                            ]; }),
                                                            _: 2
                                                        }, 1032, ["disabled", "icon", "onClick"])
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, null, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                            plain: "",
                                                            type: "primary",
                                                            onClick: function ($event) { return (HaogetAccImg(scope.row.id, scope.$index)); }
                                                        }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)(" 查看账号截图 ")
                                                            ]; }),
                                                            _: 2
                                                        }, 1032, ["onClick"]),
                                                        (imgDialogGlance.value == scope.$index)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dialog, {
                                                                key: 0,
                                                                modelValue: imgsvisible.value,
                                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((imgsvisible).value = $event); }),
                                                                "before-close": function () { imgDialogGlance.value = -1; },
                                                                title: scope.row.myAccount + ' ' + scope.row.myID + ' 截图',
                                                                top: "3vh",
                                                                width: "95%",
                                                                "append-to-body": ""
                                                            }, {
                                                                footer: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createElementVNode)("span", _hoisted_3, [
                                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                                            type: "primary",
                                                                            onClick: _cache[0] || (_cache[0] = function ($event) { return (imgDialogGlance.value = -1); })
                                                                        }, {
                                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                                (0, vue_2.createTextVNode)("关闭关闭关闭 关闭对话框")
                                                                            ]; }),
                                                                            _: 1
                                                                        })
                                                                    ])
                                                                ]; }),
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createElementVNode)("span", null, [
                                                                        (0, vue_2.createVNode)(lol_img_card_vue_1.default, {
                                                                            imgUrls: (0, vue_2.unref)(imgUrls),
                                                                            onGotoUpload: function ($event) { return (gotoUpload(scope.$index)); },
                                                                            id: scope.row.id,
                                                                            onRefresh: function ($event) { return (HaogetAccImg(scope.row.id, scope.$index)); }
                                                                        }, null, 8, ["imgUrls", "onGotoUpload", "id", "onRefresh"])
                                                                    ])
                                                                ]; }),
                                                                _: 2
                                                            }, 1032, ["modelValue", "before-close", "title"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_el_table_column, {
                                                    label: "",
                                                    prop: ""
                                                }, {
                                                    default: (0, vue_2.withCtx)(function (scope) { return [
                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                            plain: "",
                                                            type: "primary",
                                                            onClick: function ($event) { return (handleAddScreenshot(scope.$index)); }
                                                        }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)(" 添加截图 ")
                                                            ]; }),
                                                            _: 2
                                                        }, 1032, ["onClick"]),
                                                        (dialogVisibleIMG.value == scope.$index)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dialog, {
                                                                key: 0,
                                                                modelValue: imgDialogVisible.value,
                                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((imgDialogVisible).value = $event); }),
                                                                "before-close": function () { dialogVisibleIMG.value = -1; },
                                                                title: '点击[确认上传]才能上传 ' + scope.row.myAccount + ' ' + scope.row.myID,
                                                                width: "80%",
                                                                style: { "min-height": "600px" },
                                                                "append-to-body": ""
                                                            }, {
                                                                footer: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createElementVNode)("span", _hoisted_4, [
                                                                        (0, vue_2.createVNode)(_component_el_button, {
                                                                            type: "primary",
                                                                            onClick: _cache[2] || (_cache[2] = function ($event) { return (dialogVisibleIMG.value = -1); })
                                                                        }, {
                                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                                (0, vue_2.createTextVNode)("关闭关闭关闭关闭 关闭对话框")
                                                                            ]; }),
                                                                            _: 1
                                                                        })
                                                                    ])
                                                                ]; }),
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createElementVNode)("span", null, [
                                                                        (0, vue_2.createVNode)(upload_acc_img_vue_1.default, {
                                                                            ids: { rowid: scope.$index + 1, connectID: (0, vue_2.unref)(store).state.user.profile.id }
                                                                        }, null, 8, ["ids"])
                                                                    ])
                                                                ]; }),
                                                                _: 2
                                                            }, 1032, ["modelValue", "before-close", "title"]))
                                                            : (0, vue_2.createCommentVNode)("", true)
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        }, 8, ["onCellClick", "data"])
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
