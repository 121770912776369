"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "home" };
var _hoisted_2 = { class: "app-body" };
var top_nav_bar_vue_1 = require("@/components/top/top-nav-bar.vue");
var element_plus_1 = require("element-plus");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'LayOut',
    setup: function (__props) {
        (0, vue_3.onMounted)(function () {
            //open()
        });
        var open = function () {
            element_plus_1.ElMessageBox.alert('傻逼', '公告', {
                confirmButtonText: '(⊙o⊙)…)',
            });
        };
        return function (_ctx, _cache) {
            var _component_RouterView = (0, vue_2.resolveComponent)("RouterView");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(top_nav_bar_vue_1.default),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(_component_RouterView)
                ])
            ]));
        };
    }
});
